.accordion-item {
	background-color: #e8edf4;
	color: #000;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	border-radius: 8px;
	margin-bottom: 1em;
	font-size: 15px;
	transition: 0.4s;
  }
  
  .accordion-title {
	font-weight: bold;
  }
  
  .accordion-item:hover {
	background-color: #73c23b;
  }
  
  .accordion-title span {
	float: right;
  }
  
  .accordion-content {
	margin-top: 1em;
	padding: 10px;
	background-color: white;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
  }